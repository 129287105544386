import Loadable from "react-loadable";
import { Loading } from "../components";

export const ScreenLayout = Loadable({
  loader: () => import("../screens/ScreenLayout/ScreenLayout"),
  loading: Loading,
});

export const MixScreen = Loadable({
  loader: () => import("../screens/MixScreen"),
  loading: Loading,
});
export const BlankPage = Loadable({
  loader: () => import("../screens/blankPage/blankPage"),
  loading: Loading,
});
export const FormCantrol = Loadable({
  loader: () => import("../components/FormCantrol/FormCantrol"),
  loading: Loading,
});

export const Login = Loadable({
  loader: () => import("../screens/Login/Login"),
  loading: Loading,
});
export const Registration = Loadable({
  loader: () => import("../screens/Registration/Registration"),
  loading: Loading,
});
export const Setpassword = Loadable({
  loader: () => import("../screens/setPassword/setPassword"),
  loading: Loading,
});
export const AdminHome = Loadable({
  loader: () => import("../screens/AdminScreen/AdminHome/AdminHome"),
  loading: Loading,
});
export const SimplyNoted = Loadable({
  loader: () => import("../screens/SimplyNoted/SimplyNoted"),
  loading: Loading,
});
export const GoogleBusiness = Loadable({
  loader: () => import("../screens/GoogleBusiness/Googlebusiness.js"),
  loading: Loading,
});
export const BusinessListing = Loadable({
  loader: () => import("../screens/BusinessListing/BusinessListing.js"),
  loading: Loading,
});

export const HomeScreen = Loadable({
  loader: () => import("../screens/HomeScreen/HomeScreen"),
  loading: Loading,
});
export const ProductSelection = Loadable({
  loader: () => import("../screens/ProductSelection/ProductSelection"),
  loading: Loading,
});
export const LocationNew = Loadable({
  loader: () => import("../screens/LocationSelection/LocationNew"),
  loading: Loading,
});
export const GoogleLocations = Loadable({
  loader: () => import("../screens/LocationSelection/GoogleLocations.js"),
  loading: Loading,
});
export const LocalAd = Loadable({
  loader: () => import("../screens/LocalAd/LocalAd"),
  loading: Loading,
});
export const LocalAdCampaign = Loadable({
  loader: () => import("../screens/LocalAdCampaigns/LocalAdCampaign"),
  loading: Loading,
});
export const CustomPromo = Loadable({
  loader: () => import("../screens/CustomPromo/CustomPromo"),
  loading: Loading,
});
export const Cart = Loadable({
  loader: () => import("../screens/Cart/Cart"),
  loading: Loading,
});
export const MyOrder = Loadable({
  loader: () => import("../screens/myOrder/myOrder"),
  loading: Loading,
});
export const MyOrderDetails = Loadable({
  loader: () => import("../screens/myOrderDetails/myOrderDetails"),
  loading: Loading,
});
export const AnnualSupplyCalculater = Loadable({
  loader: () => import("../screens/AnnualSupplyCalculator/AnnualSupplyCalculator"),
  loading: Loading,
});

export const ContactUs = Loadable({
  loader: () => import("../screens/contactUs/contactUs"),
  loading: Loading,
});
export const PrivacyPolicy = Loadable({
  loader: () => import("../screens/PrivacyPolicy/PrivacyPolicy"),
  loading: Loading,
});
export const TermsOfUse = Loadable({
  loader: () => import("../screens/TermsOfUse/TermsOfUse"),
  loading: Loading,
});
export const MediaLibrary = Loadable({
  loader: () => import("../screens/MediaLibrary/MediaLibrary"),
  loading: Loading,
});
export const HTMLEmailDemo = Loadable({
  loader: () => import("../screens/HTMLEmailDemo/HTMLEmailDemo"),
  loading: Loading,
});
export const CostEstimates = Loadable({
  loader: () => import("../screens/CostEstimates/CostEstimates"),
  loading: Loading,
});
export const SubscriptionDetails = Loadable({
  loader: () => import("../screens/SubscriptionDetails/SubscriptionDetails"),
  loading: Loading,
});
export const Thankyou = Loadable({
  loader: () => import("../screens/Thankyou/Thankyou"),
  loading: Loading,
});
export const MarketerLogin = Loadable({
  loader: () => import("../screens/MarketerLogin/MarketerLogin"),
  loading: Loading,
});
export const MarketerLocations = Loadable({
  loader: () => import("../screens/MarketerLocations/MarketerLocations"),
  loading: Loading,
});

export const AdminUsers = Loadable({
  loader: () => import("../screens/AdminScreen/ADMINUSERS/adminusers"),
  loading: Loading,
});
// export const PracticeInformation = Loadable({
//   loader: () => import('../screens/PracticeInformation/PracticeInformation'),
//   loading: Loading,
// });
// export const FeaturedPratice = Loadable({
//   loader: () => import('../screens/FeaturedPratice/FeaturedPratice'),
//   loading: Loading,
// });
// export const AnnualSupply = Loadable({
//   loader: () => import('../screens/AnnualSupply/AnnualSupply'),
//   loading: Loading,
// });
// export const LocationSelection = Loadable({
//   loader: () => import('../screens/LocationSelection/LocationSelection'),
//   loading: Loading,
// });
// export const ViewSocialPost = Loadable({
//   loader: () => import('../screens/ViewSocialPost/ViewSocialPost'),
//   loading: Loading,
// });
// export const AddSocialPost = Loadable({
//   loader: () => import('../screens/AddSocialPost/AddSocialPost'),
//   loading: Loading,
// });
// export const UserProfile = Loadable({
//   loader: () => import('../screens/UserProfile/UserProfile'),
//   loading: Loading,
// });
// export const ChangePassword = Loadable({
//   loader: () => import('../screens/changePassword/changePassword'),
//   loading: Loading,
// });
// export const Sidemodal = Loadable({
//   loader: () => import('../screens/Sidemodal/Sidemodal'),
//   loading: Loading,
// });
// export const ArtWorkInfo = Loadable({
//   loader: () => import('../screens/ArtWorkInfo/ArtWorkInfo'),
//   loading: Loading,
// });
// export const CampainOption = Loadable({
//   loader: () => import('../screens/CampainOption/CampainOption'),
//   loading: Loading,
// });
// export const CommonPopup = Loadable({
//   loader: () => import('../screens/CommonPopup/CommonPopup'),
//   loading: Loading,
// });

// NOT USE
// export const EmailMarketing = Loadable({
//   loader: () => import('../screens/EmailMarketing/EmailMarketing'),
//   loading: Loading,
// });
// export const DirectMail = Loadable({
//   loader: () => import('../screens/DirectMail/DirectMail'),
//   loading: Loading,
// });
// export const ViewPost = Loadable({
//   loader: () => import('../screens/ViewPost/ViewPost'),
//   loading: Loading,
// });

// Admin Screen

//ACS
export const Rights = Loadable({
  loader: () => import("../screens/AdminScreen/ACS/Rights/Rights"),
  loading: Loading,
});
export const Roles = Loadable({
  loader: () => import("../screens/AdminScreen/ACS/Roles/Roles"),
  loading: Loading,
});

export const Screens = Loadable({
  loader: () => import("../screens/AdminScreen/ACS/Screens/Screens"),
  loading: Loading,
});

export const Countries = Loadable({
  loader: () => import("../screens/AdminScreen/Countries/Countries"),
  loading: Loading,
});
export const LocationMaster = Loadable({
  loader: () => import("../screens/AdminScreen/LocationMaster/LocationMaster"),
  loading: Loading,
});
export const Areas = Loadable({
  loader: () => import("../screens/AdminScreen/Areas/Areas"),
  loading: Loading,
});
export const Categories = Loadable({
  loader: () => import("../screens/AdminScreen/Categories/Categories"),
  loading: Loading,
});
export const Cities = Loadable({
  loader: () => import("../screens/AdminScreen/Cities/Cities"),
  loading: Loading,
});
export const Departments = Loadable({
  loader: () => import("../screens/AdminScreen/Departments/Departments"),
  loading: Loading,
});
export const DocumentTypes = Loadable({
  loader: () => import("../screens/AdminScreen/DocumentTypes/DocumentTypes"),
  loading: Loading,
});
export const Memberships = Loadable({
  loader: () => import("../screens/AdminScreen/Memberships/Memberships"),
  loading: Loading,
});
export const Positions = Loadable({
  loader: () => import("../screens/AdminScreen/Positions/Positions"),
  loading: Loading,
});
export const ProductAssetGroups = Loadable({
  loader: () => import("../screens/AdminScreen/ProductAssetGroup/ProductAssetGroup.js"),
  loading: Loading,
});
export const InvoiceCategory = Loadable({
  loader: () => import("../screens/AdminScreen/Invoice/InvoiceCategory/InvoiceCategory.js"),
  loading: Loading,
});
export const PostalCharges = Loadable({
  loader: () => import("../screens/AdminScreen/PostalCharges/PostalCharges"),
  loading: Loading,
});
export const Regions = Loadable({
  loader: () => import("../screens/AdminScreen/Regions/Regions"),
  loading: Loading,
});
export const Segments = Loadable({
  loader: () => import("../screens/AdminScreen/Segments/Segments"),
  loading: Loading,
});
export const States = Loadable({
  loader: () => import("../screens/AdminScreen/States/States"),
  loading: Loading,
});
export const Territories = Loadable({
  loader: () => import("../screens/AdminScreen/Territories/Territories"),
  loading: Loading,
});
export const UserTypes = Loadable({
  loader: () => import("../screens/AdminScreen/UserTypes/UserTypes"),
  loading: Loading,
});
export const Zipcodes = Loadable({
  loader: () => import("../screens/AdminScreen/Zipcodes/Zipcodes"),
  loading: Loading,
});
export const GlobalModules = Loadable({
  loader: () => import("../screens/AdminScreen/GlobalModules/GlobalModules"),
  loading: Loading,
});
export const GlobalSubModules = Loadable({
  loader: () => import("../screens/AdminScreen/GlobalSubModules/GlobalSubModules"),
  loading: Loading,
});
export const GlobalAssetTypes = Loadable({
  loader: () => import("../screens/AdminScreen/GlobalAssetTypes/GlobalAssetTypes"),
  loading: Loading,
});
export const GlobalAssetSizes = Loadable({
  loader: () => import("../screens/AdminScreen/GlobalAssetSizes/GlobalAssetSizes"),
  loading: Loading,
});


export const GlobalActions = Loadable({
  loader: () => import("../screens/AdminScreen/GlobalActions/GlobalActions.js"),
  loading: Loading,
});
export const Modules = Loadable({
  loader: () => import("../screens/AdminScreen/Modules/Modules"),
  loading: Loading,
});
export const SubModules = Loadable({
  loader: () => import("../screens/AdminScreen/SubModules/SubModules"),
  loading: Loading,
});
export const AssetTypes = Loadable({
  loader: () => import("../screens/AdminScreen/AssetTypes/AssetTypes"),
  loading: Loading,
});
export const AssetSizes = Loadable({
  loader: () => import("../screens/AdminScreen/AssetSizes/AssetSizes"),
  loading: Loading,
});
export const SubModuleAssetTypes = Loadable({
  loader: () => import("../screens/AdminScreen/SubModuleAssetTypes/SubModuleAssetTypes"),
  loading: Loading,
});
export const AssetTypeAssetSizes = Loadable({
  loader: () => import("../screens/AdminScreen/AssetTypeAssetSizes/AssetTypeAssetSizes"),
  loading: Loading,
});
export const Products = Loadable({
  loader: () => import("../screens/AdminScreen/Products/Products"),
  loading: Loading,
});
export const ProductCategories = Loadable({
  loader: () => import("../screens/AdminScreen/ProductCategories/ProductCategories"),
  loading: Loading,
});
export const ProductActions = Loadable({
  loader: () => import("../screens/AdminScreen/ProductActions/ProductActions.js"),
  loading: Loading,
});
export const ProductCategorySubCategories = Loadable({
  loader: () => import("../screens/AdminScreen/ProductCategorySubCategories/ProductCategorySubCategories"),
  loading: Loading,
});
export const ProductAssets = Loadable({
  loader: () => import("../screens/AdminScreen/ProductAssets/ProductAssets"),
  loading: Loading,
});
export const Locations = Loadable({
  loader: () => import("../screens/AdminScreen/Locations/Locations"),
  loading: Loading,
});
export const Information = Loadable({
  loader: () => import("../screens/AdminScreen/Information/Information"),
  loading: Loading,
});
export const Users = Loadable({
  loader: () => import("../screens/AdminScreen/Users/Users"),
  loading: Loading,
});
export const UserLocations = Loadable({
  loader: () => import("../screens/AdminScreen/UserLocations/UserLocations"),
  loading: Loading,
});
export const LocationMemberships = Loadable({
  loader: () => import("../screens/AdminScreen/LocationMemberships/LocationMemberships"),
  loading: Loading,
});
export const UserTerritories = Loadable({
  loader: () => import("../screens/AdminScreen/UserTerritories/UserTerritories"),
  loading: Loading,
});
export const BL_ST_LensTypes = Loadable({
  loader: () => import("../screens/AdminScreen/bl/st/BL_ST_LensTypes/BL_ST_LensTypes"),
  loading: Loading,
});
export const BL_ST_Leads = Loadable({
  loader: () => import("../screens/AdminScreen/bl/st/BL_ST_Leads/BL_ST_Leads"),
  loading: Loading,
});
export const BL_ST_Configurations = Loadable({
  loader: () => import("../screens/AdminScreen/bl/st/BL_ST_Configurations/BL_ST_Configurations"),
  loading: Loading,
});
export const AutomationCalender = Loadable({
  loader: () => import("../screens/AutomationCalender/AutomationCalender"),
  loading: Loading,
});
export const GlobalFilters = Loadable({
  loader: () => import("../screens/AdminScreen/GlobalFilters/GlobalFilters"),
  loading: Loading,
});
export const Filter = Loadable({
  loader: () => import("../screens/AdminScreen/Filter/Filter"),
  loading: Loading,
});
export const ProductFilters = Loadable({
  loader: () => import("../screens/AdminScreen/ProductFilters/ProductFilters"),
  loading: Loading,
});
export const ProductFiltersDetails = Loadable({
  loader: () => import("../screens/AdminScreen/ProductFiltersDetails/ProductFiltersDetails"),
  loading: Loading,
});
export const LPTemplates = Loadable({
  loader: () => import("../screens/AdminScreen/LPTemplates/LPTemplates"),
  loading: Loading,
});
export const LPReports = Loadable({
  loader: () => import("../screens/AdminScreen/LPReports/LPReports"),
  loading: Loading,
});
export const BugTracker = Loadable({
  loader: () => import("../screens/AdminScreen/BugTracker/BugTracker"),
  loading: Loading,
});
export const SocialPost = Loadable({
  loader: () => import("../screens/AdminScreen/SocialPost/SocialPost"),
  loading: Loading,
});

// Reports
export const PracticeRegistrationAllReports = Loadable({
  loader: () => import("../screens/AdminScreen/Reports/PracticeRegistrationAllReports/PracticeRegistrationAllReports"),
  loading: Loading,
});
export const ContestSummaryReports = Loadable({
  loader: () => import("../screens/AdminScreen/Reports/ContestSummaryReports/ContestSummaryReports"),
  loading: Loading,
});
export const RegistrationsReports = Loadable({
  loader: () => import("../screens/AdminScreen/Reports/RegistrationsReports/RegistrationsReports"),
  loading: Loading,
});
export const SocialAutomationReports = Loadable({
  loader: () => import("../screens/AdminScreen/Reports/SocialAutomationReports/SocialAutomationReports"),
  loading: Loading,
});
export const CampaignOrderReports = Loadable({
  loader: () => import("../screens/AdminScreen/Reports/CampaignOrderReports/CampaignOrderReports"),
  loading: Loading,
});
export const Invoices = Loadable({
  loader: () => import("../screens/AdminScreen/Invoice/Invoices/Invoices.js"),
  loading: Loading,
});
export const EmailMarketingsReports = Loadable({
  loader: () => import("../screens/AdminScreen/Reports/EmailMarketingsReports/EmailMarketingsReports"),
  loading: Loading,
});
export const FreeAssetsReports = Loadable({
  loader: () => import("../screens/AdminScreen/Reports/FreeAssetsReports/FreeAssetsReports"),
  loading: Loading,
});
export const AssetDownloadReports = Loadable({
  loader: () => import("../screens/AdminScreen/Reports/AssetDownloadReports/AssetDownloadReports"),
  loading: Loading,
});
export const LoginLogsReports = Loadable({
  loader: () => import("../screens/AdminScreen/Reports/LoginLogsReports/LoginLogsReports.js"),
  loading: Loading,
});
export const Leads = Loadable({
  loader: () => import("../screens/AdminScreen/Leads/Leads"),
  loading: Loading,
});
export const FreeAssets = Loadable({
  loader: () => import("../screens/AdminScreen/Reports/FreeAssets/FreeAssets"),
  loading: Loading,
});
export const GlobalModuleTabs = Loadable({
  loader: () => import("../screens/AdminScreen/GlobalModuleTabs/GlobalModuleTabs"),
  loading: Loading,
});
export const Documents = Loadable({
  loader: () => import("../screens/AdminScreen/Documents/Documents"),
  loading: Loading,
});

export const Banners = Loadable({
  loader: () => import("../screens/AdminScreen/SupplyCostTool/Banners/ListBanners"),
  loading: Loading,
});

// Automation
export const Subscriptions = Loadable({
  loader: () => import("../screens/AdminScreen/Automation/Subscriptions/Subscriptions"),
  loading: Loading,
});
export const SubscriptionsHistory = Loadable({
  loader: () => import("../screens/AdminScreen/Automation/Subscriptions/SubscriptionsHistory.js"),
  loading: Loading,
});
export const PostSchedule = Loadable({
  loader: () => import("../screens/AdminScreen/Automation/PostSchedule/PostSchedule"),
  loading: Loading,
});
export const PostScheduleHistory = Loadable({
  loader: () => import("../screens/AdminScreen/Automation/PostSchedule/PostScheduleHistory"),
  loading: Loading,
});
export const CustomePostFeeds = Loadable({
  loader: () => import("../screens/AdminScreen/Automation/CustomePostFeeds/CustomePostFeeds"),
  loading: Loading,
});
export const ViewAutomationcalenders = Loadable({
  loader: () => import("../screens/AdminScreen/Automation/ViewAutomationCalender/ViewAutomationCalender"),
  loading: Loading,
});
export const CalendarAssets = Loadable({
  loader: () => import("../screens/AdminScreen/Automation/CalendarAsset/CalendarAsset"),
  loading: Loading,
});
export const EmailLogs = Loadable({
  loader: () => import("../screens/AdminScreen/EmailLogs/EmailLogs"),
  loading: Loading,
});

// SN
export const Styles = Loadable({
  loader: () => import("../screens/AdminScreen/SN/Styles/Styles"),
  loading: Loading,
});
export const GiftCards = Loadable({
  loader: () => import("../screens/AdminScreen/SN/GiftCards/GiftCards"),
  loading: Loading,
});
export const Addressbook = Loadable({
  loader: () => import("../screens/AdminScreen/SN/Addressbook/Addressbook"),
  loading: Loading,
});
export const Templates = Loadable({
  loader: () => import("../screens/AdminScreen/SN/Templates/Templates"),
  loading: Loading,
});

// 
export const LNTemplates = Loadable({
  loader: () => import("../screens/AdminScreen/LN/LNTemplate/LnTemplate.js"),
  loading: Loading,
});
export const HTMLPreview = Loadable({
  loader: () => import("../screens/AdminScreen/LN/LNTemplate/HtmlPreview.js"),
  loading: Loading,
});
export const LNCampaign = Loadable({
  loader: () => import("../screens/AdminScreen/LN/LNCampaigns/LNCampaigns.js"),
  loading: Loading,
});
export const Campaignform = Loadable({
  loader: () => import("../screens/AdminScreen/LN/LNCampaigns/LNCampaignsForm2.js"),
  loading: Loading,
});
export const LNCampaignHistory = Loadable({
  loader: () => import("../screens/AdminScreen/LN/LNCampaigns/LNCampaignHistory.js"),
  loading: Loading,
});

export const CampaignLocation = Loadable({
  loader: () => import("../screens/AdminScreen/CampaignLocation/CampaignLocation.js"),
  loading: Loading,
});
export const CampaignDetailsTab = Loadable({
  loader: () => import("../screens/AdminScreen/LN/LNCampaigns/CampaignDetailsTab.js"),
  loading: Loading,
});


// Misc
export const KeyWordChecked = Loadable({
  loader: () => import("../screens/AdminScreen/Misc/KeyWordChecked/KeyWordChecked"),
  loading: Loading,
});
export const WelcomePopup = Loadable({
  loader: () => import("../screens/AdminScreen/Misc/WelcomePopup/WelcomePopup"),
  loading: Loading,
});
export const PayPalSubscriptionPlans = Loadable({
  loader: () => import("../screens/AdminScreen/PayPalSubscriptionPlans/PayPalSubscriptionPlans"),
  loading: Loading,
});

export const AdminUrlLink = Loadable({
  loader: () => import("../screens/AdminUrlLink/AdminUrlLink"),
  loading: Loading,
});

//dashboard Screens

export const AnalyticsDashboard = Loadable({
  loader: () => import("../screens/Dashboard/Analytics_dashboard"),
  loading: Loading,
});

export const LandingpageDashboard = Loadable({
  loader: () => import("../screens/Dashboard/Landingpage_dashboard"),
  loading: Loading,
});

// Tenanat

export const TenantDomains = Loadable({
  loader: () => import("../screens/AdminScreen/Tenant/TenantDomains/TenantDomains"),
  loading: Loading,
});
export const TenantSMTP = Loadable({
  loader: () => import("../screens/AdminScreen/Tenant/TenantSMTP/TenantSMTP"),
  loading: Loading,
});
export const Tenants = Loadable({
  loader: () => import("../screens/AdminScreen/Tenant/Tenants/Tenants"),
  loading: Loading,
});
export const APILogs = Loadable({
  loader: () => import("../screens/AdminScreen/APILogs/APILogs"),
  loading: Loading,
});
export const ChatGPTtransactions = Loadable({
  loader: () => import("../screens/AdminScreen/ChatGPTtransactions/ChatGPTtransactions"),
  loading: Loading,
});

// Communication

export const CommunicationTemplates = Loadable({
  loader: () => import("../screens/AdminScreen/Communication/CommunicationTemplates/CommunicationTemplates"),
  loading: Loading,
});
export const CommunicationConfiguration = Loadable({
  loader: () => import("../screens/AdminScreen/Communication/CommunicationConfiguration/CommunicationConfiguration"),
  loading: Loading,
});
export const Communications = Loadable({
  loader: () => import("../screens/AdminScreen/Communication/Communications/Communications"),
  loading: Loading,
});

// Configurations
export const SiteConfigurations = Loadable({
  loader: () => import("../screens/AdminScreen/Configuration/SiteConfigurations/SiteConfigurations"),
  loading: Loading,
});
export const TenantConfigurations = Loadable({
  loader: () => import("../screens/AdminScreen/Configuration/TenantConfigurations/TenantConfigurations"),
  loading: Loading,
});

export const LPReportDetails = Loadable({
  loader: () => import("../screens/AdminScreen/LPReportDetails/LPReportDetails"),
  loading: Loading,
});
export const LocationDetails = Loadable({
  loader: () => import("../screens/AdminScreen/LocationDetails/LocationDetails"),
  loading: Loading,
});
export const AccessHistory = Loadable({
  loader: () => import("../screens/AdminScreen/AccessHistory/AccessHistory"),
  loading: Loading,
});
export const LandingPages = Loadable({
  loader: () => import("../screens/AdminScreen/LandingPages/LandingPages"),
  loading: Loading,
});

export const LPHistory = Loadable({
  loader: () => import("../screens/AdminScreen/LPHistory/LPHistory"),
  loading: Loading,
});
export const OrderReportIDs = Loadable({
  loader: () => import("../screens/AdminScreen/OrderReportIDs/OrderReportIDs"),
  loading: Loading,
});
export const OrderReports = Loadable({
  loader: () => import("../screens/AdminScreen/OrderReports/OrderReports"),
  loading: Loading,
});
export const Orders = Loadable({
  loader: () => import("../screens/AdminScreen/Orders/Orders"),
  loading: Loading,
});
export const ViewNotifications = Loadable({
  loader: () => import("../screens/ViewNotification/ViewNotification"),
  loading: Loading,
});

// Impersonation
export const Impersonation = Loadable({
  loader: () => import("../screens/AdminScreen/Impersonation/Impersonation"),
  loading: Loading,
});
export const ImpersonationLoginLogsReports = Loadable({
  loader: () => import("../screens/AdminScreen/ImpersonationLoginLogsReports/ImpersonationLoginLogsReports"),
  loading: Loading,
});

export const LocationFilter = Loadable({
  loader: () => import("../screens/AdminScreen/LocationFilter/LocationFilter"),
  loading: Loading,
});
export const MediaLibraryDocument = Loadable({
  loader: () => import("../screens/AdminScreen/MedialibraryDocumnet/MedialibraryDocumnet.js"),
  loading: Loading,
});


export const TemplateStyle = Loadable({
  loader: () => import("../components/TemplateStyle/TemplateStyle.js"),
  loading: Loading,
});
export const NewAddress = Loadable({
  loader: () => import("../components/NewAddress/NewAddress.js"),
  loading: Loading,
});
export const SurgeryMarketing = Loadable({
  loader: () => import("../screens/SurgeryMarketing/SurgeryMarketingCalendar.js"),
  loading: Loading,
});
export const SurgeryMarketingWeek = Loadable({
  loader: () => import("../screens/SurgeryMarketing/SurgeryMarketingWeek.js"),
  loading: Loading,
});
export const RequestMarketFund = Loadable({
  loader: () => import("../screens/RequestMarketFund/RequestMarketFund.js"),
  loading: Loading,
});
export const SupplyShop = Loadable({
  loader: () => import("../screens/SupplyShop/SupplyShop.js"),
  loading: Loading,
});
export const HtmlBuilder = Loadable({
  loader: () => import("../../htmlBuilder/App/index.tsx"),
  loading: Loading,
});
export const Categoriess = Loadable({
  loader: () => import("../screens/AdminScreen/Marketing/Categoriess/Categories.js"),
  loading: Loading,
});
export const Marketing = Loadable({
  loader: () => import("../screens/AdminScreen/Marketing/Marketing/Marketing.js"),
  loading: Loading,
});
export const MarketingLogs = Loadable({
  loader: () => import("../screens/AdminScreen/Marketing/MarketingLogs/MarketingLogs.js"),
  loading: Loading,
});

export const MPColors = Loadable({
  loader: () => import("../screens/AdminScreen/MarketPlace/MPColors/MPColors.js"),
  loading: Loading,
});
export const MPSizes = Loadable({
  loader: () => import("../screens/AdminScreen/MarketPlace/MPSizes/MPSizes.js"),
  loading: Loading,
});
export const MPProducts = Loadable({
  loader: () => import("../screens/AdminScreen/MarketPlace/MPProducts/MPProducts.js"),
  loading: Loading,
});
export const AddInventory = Loadable({
  loader: () => import("../screens/AdminScreen/MarketPlace/AddInventory/AddInventory.js"),
  loading: Loading,
});
