import { useEffect, useState } from "react";
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { BaseColor, BaseImages, FontFamily } from "./source/config";

import * as APIController from "./source/API/APIController";
import { clsGetTenantConfiguration } from "./source/API/APIParameters";
import { CommonLoader } from "./source/components";
import {
    MixScreen, BlankPage, FormCantrol, AdminUrlLink,
    HomeScreen, ProductSelection, LocalAd, LocationNew, GoogleLocations, AutomationCalender, LocalAdCampaign, CustomPromo, Cart, MyOrder, MyOrderDetails, AnnualSupplyCalculater, ContactUs, PrivacyPolicy, MediaLibrary, HTMLEmailDemo, CostEstimates, SubscriptionDetails, Thankyou, SimplyNoted, GoogleBusiness, BusinessListing,
    SurgeryMarketing, AnalyticsDashboard, LandingpageDashboard, ViewNotifications,

    //ADMIN USERS
    APILogs,
    AccessHistory,
    Addressbook,
    AdminHome,
    AdminUsers,
    Areas,
    AssetSizes,
    AssetTypeAssetSizes,
    AssetTypes,
    BL_ST_Configurations,
    BL_ST_Leads,
    BL_ST_LensTypes,
    Banners,
    BugTracker,
    CalendarAssets,
    CampaignDetailsTab,
    CampaignLocation,
    Campaignform,
    Categories,
    ChatGPTtransactions,
    Cities,
    CommunicationConfiguration,
    // Communication
    CommunicationTemplates,
    Communications,
    Countries,
    // Subscriptions
    CustomePostFeeds,
    Departments,
    DocumentTypes,
    Documents,
    EmailLogs,
    Filter,
    FreeAssets,

    // Reports
    PracticeRegistrationAllReports,
    ContestSummaryReports,
    RegistrationsReports,
    SocialAutomationReports,
    CampaignOrderReports,
    EmailMarketingsReports,
    LoginLogsReports,
    ImpersonationLoginLogsReports,
    FreeAssetsReports,
    AssetDownloadReports,

    GiftCards,
    GlobalActions,
    GlobalAssetSizes,
    GlobalAssetTypes,
    GlobalFilters,
    GlobalModuleTabs,
    GlobalModules,
    GlobalSubModules,
    HTMLPreview,
    HtmlBuilder,
    Impersonation,
    Information,
    // Misc
    KeyWordChecked,
    LNCampaign,
    LNCampaignHistory,
    // LN
    LNTemplates,
    LPHistory,
    LPReportDetails,
    LPReports,
    LPTemplates,
    LandingPages,
    Leads,
    LocationDetails,
    LocationFilter,
    LocationMaster,
    LocationMemberships,
    Locations,
    Login,
    MarketerLocations,
    MarketerLogin,
    MediaLibraryDocument,
    Memberships,
    Modules,
    OrderReportIDs,
    OrderReports,
    Invoices,
    Orders,
    PayPalSubscriptionPlans,
    Positions,
    PostSchedule,
    PostScheduleHistory,
    PostalCharges,
    ProductActions,
    ProductAssetGroups,
    InvoiceCategory,
    ProductAssets,
    ProductCategories,
    ProductCategorySubCategories,
    ProductFilters,
    ProductFiltersDetails,
    Products,
    Regions,
    Registration,
    // ACS
    Rights,
    Roles,
    ScreenLayout,
    Screens,
    Segments,
    Setpassword,
    // configration
    SiteConfigurations,
    SocialPost,
    States,
    // SN
    Styles,
    SubModuleAssetTypes,
    SubModules,
    Subscriptions,
    SubscriptionsHistory,
    Templates,
    TenantConfigurations,

    // MarketingCategories
    Categoriess,
    Marketing,
    MarketingLogs,

    // Market Place
    MPColors,
    MPProducts,
    AddInventory,
    MPSizes,

    // tenants
    TenantDomains,
    TenantSMTP,
    Tenants,
    TermsOfUse,
    Territories,
    UserLocations,
    UserTerritories,
    UserTypes,
    Users,
    ViewAutomationcalenders,
    WelcomePopup,
    SurgeryMarketingWeek,
    RequestMarketFund,
    SupplyShop,
    Zipcodes
} from "./source/config/PagePathList";
import { IsEmpty, addItemStore, authService, getItemStore, storageKeys, } from "./source/config/appConstant";

function App() {
    const [loading, setloading] = useState(true);
    const isLoggedIn = authService.checkLogin();

    useEffect(() => {
        // Update CSS variables with JavaScript  // For Global Colors And Images Call From Api as well as appconfig file
        Object.entries(BaseImages).forEach(([key, value]) => {
            document.documentElement.style.setProperty(`--${key}`, value);
        });
        Object.entries(FontFamily).forEach(([key, value]) => {
            document.documentElement.style.setProperty(`--${key}`, value);
        });
        Object.entries(BaseColor).forEach(([key, value]) => {
            document.documentElement.style.setProperty(`--${key}`, value);
        });
    }, []);

    useEffect(() => {
        if (isLoggedIn === false) {
            WsGetTenantConfiguration();
        }
    }, [isLoggedIn]);

    const WsGetTenantConfiguration = (response) => {
        setloading(true);
        const objParameter = new clsGetTenantConfiguration();
        APIController.GetTenantConfiguration(objParameter).then((response) => {
            if (response.error == null) {
                if (!IsEmpty(response.data)) {
                    localStorage.setItem("pageTitle", response.data[0].a);
                    localStorage.setItem("faviconUrl", response.data[0].l);
                    addItemStore(storageKeys.TenantConfiguration, JSON.stringify(response.data));
                    setloading(false);
                }
            }
        });
    };

    if (isLoggedIn && getItemStore(storageKeys.RoleName) == "0") {
        return (
            <div>
                <ScreenLayout>
                    <Routes>
                        <Route path="/home" element={<HomeScreen />} />
                        <Route path="/productselection" element={<ProductSelection />} />
                        <Route path="/LocalAd" element={<LocalAd />} />
                        <Route path="/locationnew" element={<LocationNew />} />
                        <Route path="/googlelocations" element={<GoogleLocations />} />
                        <Route path="/AutomationCalender" element={<AutomationCalender />} />
                        <Route path="/Localadcampaign" element={<LocalAdCampaign />} />
                        <Route path="/my-landing-page" element={<CustomPromo />} />
                        <Route path="/cart" element={<Cart />} />
                        <Route path="/myorder" element={<MyOrder />} />
                        <Route path="/myOrderdetails" element={<MyOrderDetails />} />
                        <Route path="/AnnualSupplyCalculater" element={<AnnualSupplyCalculater />} />
                        <Route path="/contactus" element={<ContactUs />} />
                        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                        <Route path="/medialibrary" element={<MediaLibrary />} />
                        <Route path="/HTMLEmailDemo" element={<HTMLEmailDemo />} />
                        <Route path="/CostEstimates" element={<CostEstimates />} />
                        <Route path="/SubscriptionDetails" element={<SubscriptionDetails />} />
                        <Route path="/thankyou" element={<Thankyou />} />
                        <Route path="/SimplyNoted" element={<SimplyNoted />} />
                        <Route path="/googlebusiness" element={<GoogleBusiness />} />
                        <Route path="/businesslisting" element={<BusinessListing />} />
                        <Route path="/marketingcalendar" element={<SurgeryMarketing />} />
                        <Route path="/surgerymarketingWeek" element={<SurgeryMarketingWeek />} />
                        <Route path="/RequestMarketFund" element={<RequestMarketFund />} />
                        <Route path="/supplyshop" element={<SupplyShop />} />

                        {/* Dashboard */}
                        <Route path="/dashboard/AnalyticsDashboard" element={<AnalyticsDashboard />} />
                        <Route path="/dashboard/LandingpageDashboard" element={<LandingpageDashboard />} />
                        <Route path="/ViewNotifications" element={<ViewNotifications />} />

                        <Route path="/linkedin" element={<LinkedInCallback />} />
                        <Route path="/mixScreen" element={<MixScreen />} />
                        <Route path="/blankpage" element={<BlankPage />} />
                        <Route path="/formcantrol" element={<FormCantrol />} />
                        <Route path="/adminurllink" element={<AdminUrlLink />} />
                        <Route path="*" element={<Navigate replace to="/home" />} />
                    </Routes>
                </ScreenLayout>
            </div>
        );
    } else if (isLoggedIn && getItemStore(storageKeys.RoleName) == "1") {
        return (
            <ScreenLayout>
                <Routes>
                    {/* Admin Screen Routes Start */}
                    <Route path="/adminhome" element={<AdminHome />} />

                    {/* Super Admin */}
                    {/* ACS */}
                    <Route path="/Super/ACS/rights" element={<Rights />} />
                    <Route path="/Super/ACS/roles" element={<Roles />} />
                    <Route path="/Super/ACS/screens" element={<Screens />} />

                    {/* BugTracker */}
                    <Route path="/Super/Bugtracker/bugtracker" element={<BugTracker />} />

                    {/* Tenant */}
                    <Route path="/Super/Tenant/tenantdomains" element={<TenantDomains />} />
                    <Route path="/Super/Tenant/tenants" element={<Tenants />} />
                    <Route path="/Super/Tenant/tenantsmtp" element={<TenantSMTP />} />
                    <Route path="/Super/Tenant/SiteConfigurations" element={<SiteConfigurations />} />
                    <Route path="/Super/Tenant/TenantConfigurations" element={<TenantConfigurations />} />
                    <Route path="/Super/Tenant/usertypes" element={<UserTypes />} />
                    <Route path="/Super/Tenant/documenttypes" element={<DocumentTypes />} />
                    <Route path="/Super/Tenant/documents" element={<Documents />} />
                    <Route path="/Super/Tenant/APILogs" element={<APILogs />} />
                    <Route path="/Super/Tenant/ChatGPTtransactions" element={<ChatGPTtransactions />} />

                    {/* Communication */}
                    <Route path="/Super/Communication/communicationtemplates" element={<CommunicationTemplates />} />
                    <Route path="/Super/Communication/CommunicationConfiguration" element={<CommunicationConfiguration />} />
                    <Route path="/Super/Communication/Communications" element={<Communications />} />

                    {/* Global */}
                    <Route path="/Super/Global/globalmodules" element={<GlobalModules />} />
                    <Route path="/Super/Global/globalsubmodules" element={<GlobalSubModules />} />
                    <Route path="/Super/Global/globalassettypes" element={<GlobalAssetTypes />} />
                    <Route path="/Super/Global/globalassetsizes" element={<GlobalAssetSizes />} />
                    <Route path="/Super/Global/globalfilters" element={<GlobalFilters />} />
                    <Route path="/Super/Global/globalmoduletabs" element={<GlobalModuleTabs />} />
                    <Route path="/Super/Global/globalactions" element={<GlobalActions />} />

                    {/* USERS */}
                    <Route path="/Super/users/adminusers" element={<AdminUsers />} />

                    {/* Admin */}
                    {/* Landing Pages */}
                    <Route path="/Admin/LandingPages/Templates" element={<LPTemplates />} />
                    <Route path="/Admin/LandingPages/LandingPages" element={<LandingPages />} />
                    <Route path="/Admin/LandingPages/Leads" element={<BL_ST_Leads />} />
                    <Route path="/Admin/LandingPages/Reports" element={<LPReports />} />

                    {/* Supply Tool */}
                    <Route path="/Admin/SupplyTool/LensTypes" element={<BL_ST_LensTypes />} />
                    <Route path="/Admin/SupplyTool/Configurations" element={<BL_ST_Configurations />} />
                    <Route path="/Admin/SupplyTool/Leads" element={<Leads />} />
                    <Route path="/Admin/SupplyTool/AccessHistory" element={<AccessHistory />} />
                    <Route path="/Admin/SupplyTool/LocationDetails" element={<LocationDetails />} />

                    {/* Orders */}
                    <Route path="/Admin/Orders/Orders" element={<Orders />} />
                    <Route path="/Admin/Orders/OrderReportIDs" element={<OrderReportIDs />} />
                    <Route path="/Admin/Orders/OrderReports" element={<OrderReports />} />
                    <Route path="/Admin/Orders/invoice" element={<Invoices />} />

                    {/* Locations */}
                    <Route path="/Admin/Locations/locations" element={<Locations />} />
                    <Route path="/Admin/Locations/Information" element={<Information />} />
                    <Route path="/Admin/Locations/users" element={<Users />} />
                    <Route path="/Admin/Locations/locationmaster" element={<LocationMaster />} />

                    {/* Soical Automation */}
                    <Route path="/Admin/SocialAutomation/calenders" element={<ViewAutomationcalenders />} />
                    <Route path="/Admin/SocialAutomation/CalendarAssets" element={<CalendarAssets />} />
                    <Route path="/Admin/SocialAutomation/CalendarNegativeKeywords" element={<KeyWordChecked />} />
                    <Route path="/Admin/SocialAutomation/Subscriptions" element={<Subscriptions />} />
                    <Route path="/SubscriptionsHistory" element={<SubscriptionsHistory />} />
                    <Route path="/Admin/SocialAutomation/PostSchedule" element={<PostSchedule />} />
                    <Route path="/Admin/SocialAutomation/EmailLogs" element={<EmailLogs />} />
                    <Route path="/Admin/SocialAutomation/SchedulerHistory" element={<PostScheduleHistory />} />
                    <Route path="/Admin/SocialAutomation/custompostfeeds" element={<CustomePostFeeds />} />

                    {/* Products */}
                    <Route path="/Admin/Configurations/Modules" element={<Modules />} />
                    <Route path="/Admin/Configurations/SubModules" element={<SubModules />} />
                    <Route path="/Admin/Configurations/AssetTypes" element={<AssetTypes />} />
                    <Route path="/Admin/Configurations/AssetSizes" element={<AssetSizes />} />
                    <Route path="/Admin/Configurations/Filter" element={<Filter />} />
                    <Route path="/Admin/Products/Products" element={<Products />} />
                    <Route path="/Admin/Products/MediaLibraryDocument" element={<MediaLibraryDocument />} />

                    {/* Reports */}
                    <Route path="/Admin/reports/PracticeRegistrationAllReports" element={<PracticeRegistrationAllReports />} />
                    <Route path="/Admin/reports/ContestSummaryReports" element={<ContestSummaryReports />} />
                    <Route path="/Admin/reports/RegistrationsReports" element={<RegistrationsReports />} />
                    <Route path="/Admin/reports/SocialAutomationReports" element={<SocialAutomationReports />} />
                    <Route path="/Admin/reports/CampaignOrderReports" element={<CampaignOrderReports />} />
                    <Route path="/Admin/reports/EmailMarketingsReports" element={<EmailMarketingsReports />} />
                    <Route path="/Admin/reports/LoginLogsReports" element={<LoginLogsReports />} />
                    <Route path="/Admin/reports/freeassets" element={<FreeAssets />} />
                    <Route path="/Admin/reports/ImpersonationLoginLogsReports" element={<ImpersonationLoginLogsReports />} />
                    <Route path="/Admin/reports/FreeAssetsReports" element={<FreeAssetsReports />} />
                    <Route path="/Admin/reports/AssetDownloadReports" element={<AssetDownloadReports />} />

                    {/* Master */}
                    <Route path="/Admin/Master/Areas" element={<Areas />} />
                    <Route path="/Admin/Master/Regions" element={<Regions />} />
                    <Route path="/Admin/Master/Territories" element={<Territories />} />
                    <Route path="/Admin/Master/Countries" element={<Countries />} />
                    <Route path="/Admin/Master/Cities" element={<Cities />} />
                    <Route path="/Admin/Master/States" element={<States />} />
                    <Route path="/Admin/Master/Zipcodes" element={<Zipcodes />} />
                    <Route path="/Admin/Master/Categories" element={<Categories />} />
                    <Route path="/Admin/Master/Memberships" element={<Memberships />} />
                    <Route path="/Admin/Master/PostageCharges" element={<PostalCharges />} />
                    <Route path="/Admin/Master/PayPalSubscriptionPlans" element={<PayPalSubscriptionPlans />} />
                    <Route path="/Admin/Master/Departments" element={<Departments />} />
                    <Route path="/Admin/Master/Positions" element={<Positions />} />
                    <Route path="/Admin/Master/ProductAssetGroups" element={<ProductAssetGroups />} />
                    <Route path="/Admin/Master/invoicecategory" element={<InvoiceCategory />} />

                    {/* SimplyNoted SN */}
                    <Route path="/Admin/sn/styles" element={<Styles />} />
                    <Route path="/Admin/sn/giftcards" element={<GiftCards />} />
                    <Route path="/Admin/sn/addressbook" element={<Addressbook />} />
                    <Route path="/Admin/sn/templates" element={<Templates />} />

                    {/* MarketingCategories */}
                    <Route path="/Admin/MC/Categories" element={<Categoriess />} />
                    <Route path="/Admin/MC/Marketing" element={<Marketing />} />
                    <Route path="/Admin/MC/MarketingLogs" element={<MarketingLogs />} />

                    {/* LN */}
                    <Route path="/admin/ln/templates" element={<LNTemplates />} />
                    <Route path="/admin/ln/campaigns" element={<LNCampaign />} />

                    {/* Market Place */}
                    <Route path="/admin/MP/color" element={<MPColors />} />
                    <Route path="/admin/MP/size" element={<MPSizes />} />
                    <Route path="/admin/MP/mpproduct" element={<MPProducts />} />
                    <Route path="/addInventory" element={<AddInventory />} />

                    <Route path="/htmlpreview" element={<HTMLPreview />} />
                    <Route path="/campaignform" element={<Campaignform />} />
                    <Route path="/campaignshistory" element={<LNCampaignHistory />} />
                    <Route path="/campaignlocation" element={<CampaignLocation />} />
                    <Route path="/campaigndetailstab" element={<CampaignDetailsTab />} />

                    <Route path="/segments" element={<Segments />} />
                    <Route path="/submoduleassettypes" element={<SubModuleAssetTypes />} />
                    <Route path="/assettypeassetsizes" element={<AssetTypeAssetSizes />} />
                    <Route path="/productcategories" element={<ProductCategories />} />
                    <Route path="/productactions" element={<ProductActions />} />
                    <Route path="/productcategorysubcategories" element={<ProductCategorySubCategories />} />
                    <Route path="/productassets" element={<ProductAssets />} />
                    <Route path="/userlocations" element={<UserLocations />} />
                    <Route
                        path="/locationmemberships"
                        element={<LocationMemberships />}
                    />
                    <Route path="/userterritories" element={<UserTerritories />} />
                    <Route path="/bl/st/banners" element={<Banners />} />
                    <Route path="/productfilters" element={<ProductFilters />} />
                    <Route path="/productfilterdetails" element={<ProductFiltersDetails />} />
                    <Route path="/socialpost" element={<SocialPost />} />
                    <Route path="/misc/welcomepopup" element={<WelcomePopup />} />

                    <Route path="/lpreportdetails" element={<LPReportDetails />} />
                    <Route path="/OrderReportIDs" element={<OrderReportIDs />} />
                    <Route path="/OrderReports" element={<OrderReports />} />
                    <Route path="/lphistory" element={<LPHistory />} />
                    <Route path="/Impersonation" element={<Impersonation />} />

                    <Route path="/LocationFilter" element={<LocationFilter />} />

                    <Route path="*" element={<Navigate replace to="/adminhome" />} />
                </Routes>
            </ScreenLayout>
        );
    } else if (isLoggedIn && getItemStore(storageKeys.RoleName) == "2") {
        return (
            <div>
                <ScreenLayout>
                    <Routes>
                        <Route path="/MarketerLogin" element={<MarketerLogin />} />
                        <Route path="/MarketerLocations" element={<MarketerLocations />} />
                        <Route path="/contactus" element={<ContactUs />} />
                    </Routes>
                </ScreenLayout>
            </div>
        );
    } else {
        if (!loading) {
            return (
                <div>
                    <Routes>
                        <Route path="/linkedin" component={LinkedInCallback} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/Registration" element={<Registration />} />
                        <Route path="/setPassword" element={<Setpassword />} />
                        <Route path="/thankyou" element={<Thankyou />} />
                        <Route path="*" element={<Navigate replace to="/login" />} />
                        <Route path="/htmlBuilder" element={<HtmlBuilder />} />
                        <Route path="/termsofuse" element={<TermsOfUse />} />
                        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                    </Routes>
                </div>
            );
        } else {
            return (
                <div>
                    <CommonLoader />
                </div>
            );
        }
    }
}

export default App;
